@use '@/styles/utils/mixins.scss' as *;
.bannerWrapper {
  background: $kiaMidnightBlack;
  .bannerSectionWrapper {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  .slideDesktop {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
  .slideMobile {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  .heroBannerContentWrapper {
    display: flex;
    position: relative;
    align-self: stretch;
  }
  .heroSwiper {
    position: relative;
    .heroSwiperWrap {
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      display: flex;
      align-items: center;
      min-height: calc(100vh - 83px);
      position: relative;
      z-index: 2;
      padding: 80px 0;
      .bannerImage {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          opacity: 0.5;
          background: linear-gradient(
            90deg,
            rgba(5, 20, 31, 1) 0%,
            rgba(5, 20, 31, 0) 80%
          );
        }
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: linear-gradient(
            0deg,
            rgba(5, 20, 31, 1) 0%,
            rgba(5, 20, 31, 0) 45%
          );
          @media screen and (max-width: 576px) {
            display: none;
          }
        }
      }
      @include mob() {
        min-height: 300px;
      }

      .heroImage {
        z-index: -1;
        @media screen and (max-width: 1366px) {
          object-fit: cover;
        }
      }
    }
    .swiper-button-prev {
      left: 24px;
    }
    .swiper-button-next {
      right: 24px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      font-size: 0;
      width: 48px;
      height: 48px;
      margin-top: -26px;
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: 48px;
      height: 48px;
    }
    .swiper-button-prev::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='48' height='49' viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24' cy='24.4531' r='24' fill='%2305141F' fill-opacity='0.4'/%3E%3Cpath d='M29.3334 23.7865H21.2201L24.9467 20.0598L24.0001 19.1198L18.6667 24.4531L24.0001 29.7865L24.9401 28.8465L21.2201 25.1198H29.3334V23.7865Z' fill='white'/%3E%3C/svg%3E%0A");
    }
    .swiper-button-next::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='49' viewBox='0 0 48 49' fill='none'%3E%3Ccircle cx='24' cy='24.4531' r='24' fill='%2305141F' fill-opacity='0.4'/%3E%3Cpath d='M24 19.1198L23.06 20.0598L26.78 23.7865H18.6666V25.1198H26.78L23.06 28.8465L24 29.7865L29.3333 24.4531L24 19.1198Z' fill='white'/%3E%3C/svg%3E");
    }
  }
  .heroBannerContent {
    align-self: center;
  }
  .videoPlayerController {
    display: none !important;
  }
  .bannerCta {
    padding: 13px 30px;
    line-height: 1;
    min-width: 176px;
    @include mob() {
      padding: 9px 20px;
      min-width: auto;
    }
  }
  .disclaimerLink {
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
    width: max-content;
    background-color: transparent;
    padding: 0;
    border: 0;
    color: $kiaPolarWhite;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    border-bottom: 1px solid $kiaPolarWhite;
    &:hover,
    &:active {
      border-bottom: 1px solid transparent;
      color: $kiaPolarWhite;
    }
    &.active {
      border-bottom: 1px solid transparent;
      color: $kiaPolarWhite;
    }
  }

  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
        @include mob() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 20px;
        line-height: 1;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .bannerMobileImg {
      width: 100%;
    }
  }
  .videoPlayer {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @media screen and (max-width: $minTablet) {
      position: relative;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: linear-gradient(
        90deg,
        rgba(5, 20, 31, 1) 0%,
        rgba(5, 20, 31, 0) 80%
      );
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: linear-gradient(
        0deg,
        rgba(5, 20, 31, 1) 0%,
        rgba(5, 20, 31, 0) 45%
      );
      @media screen and (max-width: 576px) {
        display: none;
      }
    }

    &Controller {
      position: absolute;
      z-index: 9;
      bottom: 0px;
      right: 0;
      display: flex;
      justify-content: flex-end;
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;

      &:hover {
        opacity: 0.9;
      }
      @media screen and (max-width: $minTablet) {
        bottom: 50px;
      }
    }
    &Frame {
      height: 100%;
      width: 100%;
      max-width: unset;
      object-fit: cover;
      object-position: center;
    }
    .videoPlayerController {
      display: none !important;
    }
  }
  &.leftAlign {
    .horizontalFromText {
      display: none;
    }
  }
  &.rightAlign {
    .heroBannerContentWrapper {
      justify-content: flex-end;
    }
    .headingElement {
      text-align: right;
    }
    .horizontalFromText {
      display: none;
    }
    .bannerFeatures {
      justify-content: flex-end;
    }
    .bannerButtons {
      justify-content: flex-end;
    }
  }
  &.topAlign {
    .heroBannerContentWrapper {
      justify-content: center;
    }
    .heroBannerContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
    .videoPlayerController {
      display: none;
    }
    .heroSwiper .heroSwiperWrap .bannerImage,
    .videoPlayer {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background: linear-gradient(
          90deg,
          rgba(5, 20, 31, 1) 0%,
          rgba(5, 20, 31, 0) 80%
        );
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        background: linear-gradient(
          0deg,
          rgba(5, 20, 31, 1) 0%,
          rgba(5, 20, 31, 0) 45%
        );
        @media screen and (max-width: 576px) {
          display: none;
        }
      }
      .videoPlayerController {
        display: none;
      }
    }
    .horizontalFromText {
      display: none;
    }
  }
  &.centerAlign {
    .heroBannerContentWrapper {
      justify-content: center;
    }
    .heroBannerContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
    .videoPlayerController {
      display: none;
    }
    .heroSwiper .heroSwiperWrap .bannerImage,
    .videoPlayer {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background: linear-gradient(
          90deg,
          rgba(5, 20, 31, 1) 0%,
          rgba(5, 20, 31, 0) 80%
        );
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        background: linear-gradient(
          0deg,
          rgba(5, 20, 31, 1) 0%,
          rgba(5, 20, 31, 0) 45%
        );
        @media screen and (max-width: 576px) {
          display: none;
        }
      }
      .videoPlayerController {
        display: none;
      }
    }
    .horizontalFromText {
      display: none;
    }
  }
}
